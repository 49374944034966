import { isEqual } from 'es-toolkit'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import { selectDashboardStatus, getBopMonitoringSummary } from 'slices/dashboardSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { selectUsersStatus, getDisplayFilter, updateDisplayFilter } from 'slices/usersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  GroupRadioButton,
  PivotItem,
  PivotOuterIndex,
  AmountCard,
  NotSelectedPlaceholder,
  Chart,
  BadgeLabel,
  GraphSelectButton,
  GroupedGraphSelectButton,
} from 'components/common'
import { PLACE_HOLDER_TYPES } from 'components/common/NotSelectedPlaceholder/NotSelectedPlaceholder'
import { UpdateLabel } from 'components/common/UpdateLabel/UpdateLabel'

import { useBopLaborCosts } from 'hooks/useBopLaborCosts'
import { useBopMonitoringProfitMargin } from 'hooks/useBopMonitoringProfitMargin'
import { useBopMonitoringTotalAndWorkspaces } from 'hooks/useBopMonitoringTotalAndWorkspace'
import { useBopMonitoringUnitCosts } from 'hooks/useBopMonitoringUnitCosts'
import useDateQuery from 'hooks/useDateQuery'
import { useGraphSelectButton } from 'hooks/useGraphSelectButton'

import { BopMonitoringChartLaborCostsBalance } from './BopMonitoringChartLaborCostsBalance'
import { BopMonitoringChartProfitMargin } from './BopMonitoringChartProfitMargin'
import { BopMonitoringChartTotal } from './BopMonitoringChartTotal'
import { BopMonitoringChartWorkspaces } from './BopMonitoringChartWorkspaces'
import { BOP_TYPE } from './utils'

const toggleButtonItemList = [
  { id: BOP_TYPE.ESTIMATE, label: '見込' },
  { id: BOP_TYPE.ACTUAL, label: '実績' },
]

const graphList = [
  { header: '合算', key: 'total' },
  { header: 'ワークスペース別', key: 'workspaces' },
  { header: 'ワークスペース別利益率', key: 'profitMargin' },
  { header: '労務費バランス', key: 'laborCostsBalance' },
]

type Props = {
  onSelectedWorkspacesChange: (items: number[]) => void
  onSelectedKeyScheduleTypesChange: (items: number[]) => void
}

export const BopMonitoringSummary = ({ onSelectedWorkspacesChange, onSelectedKeyScheduleTypesChange }: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const [selectedBopType, setSelectedBopType] = useState(BOP_TYPE.ESTIMATE)
  const [selectedBopTypeUnitCosts, setSelectedBopTypeUnitCosts] = useState(BOP_TYPE.ESTIMATE)
  const [isPercentage, setIsPercentage] = useState(false)
  const [isManHour, setIsManHour] = useState(false)
  const [graphPivotIndex, setGraphPivotIndex] = useState(0)
  const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState<number[]>([])

  const {
    keyItems: filterKeyScheduleTypeItems,
    selectedGraphs: selectedKeyScheduleTypeGraphs,
    setSelectedGraphs: setSelectedKeyScheduleTypeGraphs,
  } = useGraphSelectButton()
  const { totalChartOptions, workspaceChartOptions } = useBopMonitoringTotalAndWorkspaces(selectedBopType)
  const { profitMarginChartOptions } = useBopMonitoringProfitMargin(selectedBopType)
  const { unitCostsChartOptions } = useBopMonitoringUnitCosts(selectedBopTypeUnitCosts, isManHour)
  const { summaryLaborCostsGraphOptions } = useBopLaborCosts(selectedBopType, isPercentage)
  const date = useDateQuery()

  const dispatch = useDispatch()

  const { bopMonitoring } = useSelector(selectDashboardStatus, shallowEqual)
  const { displayFilter, isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const disabled = useMemo(() => filterKeyScheduleTypeItems.length === 0, [filterKeyScheduleTypeItems])

  useEffect(() => {
    dispatch(getDisplayFilter())
  }, [dispatch])

  useEffect(() => {
    dispatch(getBopMonitoringSummary(date, { displayFilter: true }))
    // 選択されている日付が変更された時、displayFilterからselectedWorkspaceIds、selectedKeyScheduleTypeGraphsを作成し直す
    setSelectedWorkspaceIds(
      displayFilter?.bopDashboard.workspaceData
        .filter(workspace => workspace.isFilteredInSummary)
        .map(workspace => workspace.id) || []
    )

    setSelectedKeyScheduleTypeGraphs(
      displayFilter?.bopDashboard.workspaceData
        .flatMap(df => df.scheduleTypeData)
        .filter(scheduleType => scheduleType.isFilteredInUnitCosts)
        .map(scheduleType => scheduleType.id) || []
    )
  }, [dispatch, date, displayFilter?.bopDashboard.workspaceData, setSelectedKeyScheduleTypeGraphs])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const displayData = useMemo(() => {
    if (!bopMonitoring) {
      return
    }

    return selectedBopType === BOP_TYPE.ESTIMATE ? bopMonitoring.estimate : bopMonitoring.actual
  }, [bopMonitoring, selectedBopType])

  const filterItems = useMemo(
    () =>
      partialWorkspaces.map(workspace => ({
        key: workspace.id,
        label: workspace.name,
        checked: selectedWorkspaceIds.includes(workspace.id),
      })),
    [partialWorkspaces, selectedWorkspaceIds]
  )

  const handleToggleChange = useCallback((id: string) => {
    setSelectedBopType(id)
  }, [])

  const handlePercentageCheckBoxClick = useCallback((checked: boolean) => setIsPercentage(checked), [])

  const handleWorkspaceSelect = useCallback(
    (items: number[]) => {
      if (isEqual(items, selectedWorkspaceIds)) {
        return
      }

      const workspacesParam = items.length === 0 ? {} : { workspaceIds: items.join() }
      const keyScheduleTypesParam =
        selectedKeyScheduleTypeGraphs.length === 0 ? {} : { scheduleTypeIds: selectedKeyScheduleTypeGraphs.join() }
      dispatch(getBopMonitoringSummary(date, { ...workspacesParam, ...keyScheduleTypesParam }))
      setSelectedWorkspaceIds(items)
      onSelectedWorkspacesChange(items) // 親のBopMonitoringにも通知
    },
    [selectedWorkspaceIds, selectedKeyScheduleTypeGraphs, dispatch, date, onSelectedWorkspacesChange]
  )

  const handleWorkspaceFilterSaveButtonClick = useCallback(() => {
    if (!displayFilter) {
      return
    }

    setSubmitted(true)
    const updateWorkspaces = displayFilter.bopDashboard.workspaceData.map(df => {
      return { ...df, isFilteredInSummary: selectedWorkspaceIds.includes(df.id) }
    })

    dispatch(updateDisplayFilter({ bopDashboard: { workspaceData: updateWorkspaces } }))
  }, [dispatch, displayFilter, selectedWorkspaceIds])

  const getAmountCardLabel = useCallback(
    (text: string) => (selectedBopType === BOP_TYPE.ACTUAL ? text : `${text}見込`),
    [selectedBopType]
  )

  const chart = useMemo(() => {
    switch (graphList[graphPivotIndex].key) {
      case 'total':
        return <BopMonitoringChartTotal chartOptions={totalChartOptions} />
      case 'workspaces':
        return <BopMonitoringChartWorkspaces chartOptions={workspaceChartOptions} />
      case 'profitMargin':
        return <BopMonitoringChartProfitMargin chartOptions={profitMarginChartOptions} />
      case 'laborCostsBalance':
        return (
          <BopMonitoringChartLaborCostsBalance
            chartOptions={summaryLaborCostsGraphOptions}
            checked={isPercentage}
            onChange={handlePercentageCheckBoxClick}
          />
        )
      default:
        return <BopMonitoringChartTotal chartOptions={totalChartOptions} />
    }
  }, [
    graphPivotIndex,
    totalChartOptions,
    workspaceChartOptions,
    profitMarginChartOptions,
    summaryLaborCostsGraphOptions,
    isPercentage,
    handlePercentageCheckBoxClick,
  ])

  const handleUnitCostsToggleChange = useCallback((id: string) => {
    setSelectedBopTypeUnitCosts(id)
  }, [])

  const handleSaveButtonClick = useCallback(() => {
    if (!displayFilter) {
      return
    }
    setSubmitted(true)
    const updateScheduleTypes = displayFilter.bopDashboard.workspaceData.map(df => {
      const scheduleTypeData = df.scheduleTypeData.map(scheduleType => ({
        ...scheduleType,
        isFilteredInUnitCosts: selectedKeyScheduleTypeGraphs.includes(scheduleType.id),
      }))
      return { id: df.id, isFilteredInSummary: df.isFilteredInSummary, scheduleTypeData }
    })
    dispatch(updateDisplayFilter({ bopDashboard: { workspaceData: updateScheduleTypes } }))
  }, [dispatch, displayFilter, selectedKeyScheduleTypeGraphs])

  const handleSelectedKeyScheduleTypesChange = useCallback(
    (items: number[]) => {
      if (isEqual(items, selectedKeyScheduleTypeGraphs)) {
        return
      }

      const workspacesParam = selectedWorkspaceIds.length === 0 ? {} : { workspaceIds: selectedWorkspaceIds.join() }
      const keyScheduleTypesParam = items.length === 0 ? {} : { scheduleTypeIds: items.join() }
      dispatch(getBopMonitoringSummary(date, { ...workspacesParam, ...keyScheduleTypesParam }))
      setSelectedKeyScheduleTypeGraphs(items)
      onSelectedKeyScheduleTypesChange(items) // 親のBopMonitoringにも通知
    },
    [
      date,
      dispatch,
      onSelectedKeyScheduleTypesChange,
      selectedKeyScheduleTypeGraphs,
      selectedWorkspaceIds,
      setSelectedKeyScheduleTypeGraphs,
    ]
  )

  return (
    <>
      <Card className="mt-2 mb-3">
        <CardBody className="p-4">
          <div className="d-flex align-items-baseline">
            <CardTitle className="mb-3 fw-bold font-large text-nowrap">収支</CardTitle>
            <GraphSelectButton
              items={filterItems}
              selectedGraphs={selectedWorkspaceIds}
              onChange={handleWorkspaceSelect}
              text="表示ワークスペース"
              onSaveButtonClick={handleWorkspaceFilterSaveButtonClick}
              date={date}
            />
          </div>

          {selectedWorkspaceIds.length === 0 ? (
            <NotSelectedPlaceholder type={PLACE_HOLDER_TYPES.WORKSPACE} />
          ) : (
            <>
              <GroupRadioButton items={toggleButtonItemList} onChange={handleToggleChange} />
              <div className="d-flex gap-2 mt-2 mb-3">
                <AmountCard
                  amount={displayData?.totalWorkspaceData.totalSales.toLocaleString() || '0'}
                  badgeLabel={getAmountCardLabel('売上')}
                  badgeColor="primary"
                  className="h-100 w-100"
                  unit="円"
                />
                <AmountCard
                  amount={displayData?.totalWorkspaceData.totalCosts.toLocaleString() || '0'}
                  badgeLabel={getAmountCardLabel('費用')}
                  badgeColor="danger-stronger-middle"
                  className="h-100 w-100"
                  unit="円"
                />
                <AmountCard
                  amount={displayData?.totalWorkspaceData.profit.toLocaleString() || '0'}
                  ratio={displayData?.totalWorkspaceData.profitRatio.toString() || '0'}
                  badgeLabel={getAmountCardLabel('利益')}
                  badgeColor="success"
                  className="h-100 w-100"
                  unit="円"
                />
              </div>

              <PivotOuterIndex selectedIndex={graphPivotIndex} onChange={setGraphPivotIndex}>
                {graphList.map(({ header, key }) => (
                  <PivotItem headerText={header} key={key} />
                ))}
              </PivotOuterIndex>
              {chart}
              <UpdateLabel updatedAt={bopMonitoring?.updatedAt} />
            </>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody className="p-4">
          <div className="d-flex align-items-baseline">
            <CardTitle className="mb-3 fw-bold font-large text-nowrap">単位原価</CardTitle>
            <div className="w-100 d-flex justify-content-end">
              <GroupedGraphSelectButton
                items={filterKeyScheduleTypeItems}
                selectedGraphs={selectedKeyScheduleTypeGraphs}
                onChange={handleSelectedKeyScheduleTypesChange}
                disabled={disabled}
                text="表示キー作業"
                onSaveButtonClick={handleSaveButtonClick}
                date={date}
              />
            </div>
          </div>
          {selectedKeyScheduleTypeGraphs.length === 0 ? (
            <NotSelectedPlaceholder type={PLACE_HOLDER_TYPES.UNIT_COSTS} />
          ) : (
            <>
              <GroupRadioButton items={toggleButtonItemList} onChange={handleUnitCostsToggleChange} />
              <div className="d-flex mt-3 mb-3 form-check me-3">
                <Input
                  className="form-check-input me-2"
                  id="estimate"
                  checked={isManHour}
                  type="checkbox"
                  onChange={e => setIsManHour(e.target.checked)}
                />
                <Label className="form-check-label" for="estimate">
                  人時表示
                </Label>
              </div>
              {!!unitCostsChartOptions && <Chart options={unitCostsChartOptions} />}
              <div className="d-flex">
                <BadgeLabel label="変動直接労務費" color="danger-stronger-middle" />
                <BadgeLabel label="変動間接労務費" color="danger-middle" />
                {!isManHour && <BadgeLabel label="資材・材料費" color="light-gray" />}
              </div>
              <UpdateLabel updatedAt={bopMonitoring?.updatedAt} />
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}
