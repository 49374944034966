import dayjs from 'dayjs'
import { useCallback, useEffect } from 'react'
import { Row, Col } from 'reactstrap'

import type { ColorType } from 'api/types'

import { DividedTimeSelect } from 'components/common'
import { TIME_INTERVAL } from 'components/common/constants'

import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

import styles from './TimeRangeSelector.module.scss'

export type SelectedScheduleType = {
  id: number
  name: string
  color?: ColorType
}

type Props = {
  open: boolean
  startHour: string
  startMinute: string
  endHour: string
  endMinute: string
  onClose: () => void
  onTimeChange: (startHour: string, startMinute: string, endHour: string, endMinute: string) => void
}

export const TimeRangeSelector = (props: Props) => {
  const { open, startHour, startMinute, endHour, endMinute, onClose, onTimeChange } = props
  const { businessStartTime, businessEndTime, ceiledCurrentTime, getHourOver24h, timeRange } = useBusinessTime({
    interval: TIME_INTERVAL.FIVE,
  })
  const { planWorkDate } = usePlans()

  useEffect(() => {
    const [businessStartHour, businessStartMinute] = businessStartTime.split(':').map(Number)
    const [businessEndHour, businessEndMinute] = businessEndTime.split(':').map(Number)
    const currentDate = dayjs(`${planWorkDate} ${ceiledCurrentTime}`)

    const getCalcStartTime = () => {
      const currentHour = getHourOver24h(currentDate.format('HH:mm'))
      const currentMinute = currentDate.minute()

      // 営業開始時間前の場合は営業開始時間を設定する
      return businessEndHour < Number(currentHour) ||
        (businessEndHour === Number(currentHour) && businessEndMinute <= Number(currentMinute))
        ? [businessStartHour.toString().padStart(2, '0'), businessStartMinute.toString().padStart(2, '0')]
        : [currentHour.toString().padStart(2, '0'), currentMinute.toString().padStart(2, '0')]
    }

    const [calcStartHour, calcStartMinute] = getCalcStartTime()

    const getCalcEndTime = () => {
      // endHourは、24時間以降を考慮したstartHourに、数値計算上+1した値とする。
      const tempEndHour = (Number(calcStartHour) + 1).toString().padStart(2, '0')
      const tempEndMinute = calcStartMinute.toString().padStart(2, '0')

      // 営業終了時間とcalcEndHour・calcEndMinuteを比較し、短い方をendHour・endMinuteに代入する
      return businessEndHour < Number(tempEndHour) ||
        (businessEndHour === Number(tempEndHour) && businessEndMinute <= Number(tempEndMinute))
        ? [businessEndHour.toString().padStart(2, '0'), businessEndMinute.toString().padStart(2, '0')]
        : [tempEndHour, tempEndMinute]
    }

    const [calcEndHour, calcEndMinute] = getCalcEndTime()

    onTimeChange(calcStartHour, calcStartMinute, calcEndHour, calcEndMinute)
  }, [getHourOver24h, onTimeChange, planWorkDate, businessEndTime, ceiledCurrentTime, businessStartTime])

  const handleStartTimeChange = useCallback(
    (hour: string, minute: string) => {
      onTimeChange(hour, minute, endHour, endMinute)
    },
    [endHour, endMinute, onTimeChange]
  )

  const handleEndTimeChange = useCallback(
    (hour: string, minute: string) => {
      onTimeChange(startHour, startMinute, hour, minute)
    },
    [onTimeChange, startHour, startMinute]
  )

  return (
    <>
      <div className={open ? styles.backdrop : ''} />
      <div className={`px-4 pt-4 ${open ? styles.sidebarOpen : styles.sidebar}`}>
        <Row>
          <Col>時間帯を入力してください</Col>
          <Col sm={{ size: 'auto', offset: 1 }}>
            <i className="icf-close" onClick={onClose} />
          </Col>
        </Row>

        <Row className="pt-4 pb-2">
          <Col>開始時間</Col>
        </Row>
        <Row>
          <Col>
            <DividedTimeSelect
              hour={startHour}
              minute={startMinute}
              label="から"
              onChange={handleStartTimeChange}
              range={timeRange.start}
              roundingInterval={TIME_INTERVAL.FIVE}
            />
          </Col>
        </Row>
        <Row className="pt-4 pb-2">
          <Col>終了時間</Col>
        </Row>
        <Row>
          <Col>
            <DividedTimeSelect
              hour={endHour}
              minute={endMinute}
              label="まで"
              onChange={handleEndTimeChange}
              range={timeRange.end}
              roundingInterval={TIME_INTERVAL.FIVE}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}
