import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep, merge, mergeWith } from 'es-toolkit'
import moment from 'moment'

import { COLOR_TYPES } from 'api/constants'
import * as API from 'api/sessions/sessions'
import type {
  ConfirmPasswordResetParams,
  LoginParams,
  RequestPasswordResetParams,
  SessionResponse,
  UpdateUserPasswordParams,
} from 'api/sessions/types'
import type { ColorType } from 'api/types'
import { ROLE } from 'api/users/constants'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams, INCORRECT_OLD_PASSWORD_MESSAGE, ERROR_STATUS_CODE, makeErrorMessage } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type TeamState = {
  workspaceId: number
  workspaceName: string
  groupId: number
  groupName: string
  groupColor: ColorType
  workerId: number
  workerName: string
}

type SessionState = SessionResponse & {
  isRequesting: boolean
  errorMessage: string
  loggedIn: boolean
  activated: boolean
  team: TeamState
}

const cleanState: SessionState = {
  loggedIn: false,
  activated: false,
  newPasswordRequired: false,
  session: null,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  expirationDate: null,
  shouldUpdate: false,
  user: {
    userId: '',
    email: '',
    userHasTenants: [],
    isServiceManagementRole: false,
    createdAt: '',
    updatedAt: '',
  },
  team: {
    workspaceId: 0,
    workspaceName: '',
    groupId: 0,
    groupName: '',
    groupColor: COLOR_TYPES.SILVER,
    workerId: 0,
    workerName: '',
  },
  isRequesting: false,
  errorMessage: '',
}

const initialState = cloneDeep(cleanState)

const sessionStorageValue = sessionStorage.getItem('sessionState')
if (sessionStorageValue) {
  const storageSessionState = JSON.parse(sessionStorageValue) as SessionState
  merge(initialState, storageSessionState)
}

export const sessionSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    requestLogin: state => {
      merge(state, { ...cleanState, isRequesting: true })
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    loginSuccess: (state, action: PayloadAction<SessionResponse>) => {
      const srcState = {
        ...action.payload,
        isRequesting: false,
        loggedIn: true,
        activated: !action.payload.newPasswordRequired,
      }
      mergeWith(state, srcState, (_objValue, srcValue, key) => {
        if (key === 'userHasTenants') {
          return srcValue
        }
      })
      if (!action.payload.newPasswordRequired) {
        if (action.payload.user.userHasTenants[0].role === ROLE.TENANT_ADMIN) {
          // tenantAdmin は強制的に 30 分でログアウトさせる
          state.expirationDate = moment().add(30, 'minutes').toISOString()
        } else {
          // 念のためにセッションが切れる 30 分前にリフレッシュするよう期限を縮める
          state.expirationDate = moment(state.expirationDate).subtract(30, 'minutes').toISOString()
        }
      }

      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    activateSuccess: (state, action: PayloadAction<SessionResponse>) => {
      const srcState = { ...action.payload, isRequesting: false, loggedIn: true, activated: true }
      merge(state, srcState)
      if (action.payload.user.userHasTenants[0].role === ROLE.TENANT_ADMIN) {
        state.expirationDate = moment().add(30, 'minutes').toISOString()
      } else {
        state.expirationDate = moment(state.expirationDate).subtract(30, 'minutes').toISOString()
      }

      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    updateAccountInformationSuccess: (state, action: PayloadAction<SessionResponse>) => {
      const srcState = {
        ...action.payload,
        isRequesting: false,
        errorMessage: '',
      }
      merge(state, srcState)
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    refreshSuccess: (state, action: PayloadAction<SessionResponse>) => {
      merge(state, action.payload)
      state.expirationDate = moment(state.expirationDate).subtract(30, 'minutes').toISOString()
    },
    sessionClear: state => {
      merge(state, cleanState)
      sessionStorage.removeItem('sessionState')
    },
    setTeamWorkspace: (state, action: PayloadAction<{ workspaceId: number; workspaceName: string }>) => {
      state.team.workspaceId = action.payload.workspaceId
      state.team.workspaceName = action.payload.workspaceName
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    setTeamGroup: (state, action: PayloadAction<{ groupId: number; groupName: string; groupColor: ColorType }>) => {
      state.team.groupId = action.payload.groupId
      state.team.groupName = action.payload.groupName
      state.team.groupColor = action.payload.groupColor
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    setTeamWorker: (state, action: PayloadAction<{ workerId: number; workerName: string }>) => {
      state.team.workerId = action.payload.workerId
      state.team.workerName = action.payload.workerName
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    confirmPasswordResetSuccess: state => {
      state.isRequesting = false
    },
    requestPasswordResetSuccess: state => {
      state.isRequesting = false
    },
    setUserNickname: (state, action: PayloadAction<string>) => {
      state.user.userHasTenants[0].nickname = action.payload
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
  },
})

export const {
  requestLogin,
  clearErrorMessage,
  startRequest,
  apiFailure,
  loginSuccess,
  activateSuccess,
  updateAccountInformationSuccess,
  refreshSuccess,
  sessionClear,
  setTeamWorkspace,
  setTeamGroup,
  setTeamWorker,
  confirmPasswordResetSuccess,
  requestPasswordResetSuccess,
  setUserNickname,
} = sessionSlice.actions

export const login =
  ({ email, password }: LoginParams): AppThunk =>
  async dispatch => {
    dispatch(requestLogin())
    dispatch(Spinner.start())
    try {
      const res = await API.login({ email, password })
      // signup のときには res.user は空の型で返ってくるので
      // API.SessionResponse に型を合わせつつ activate で使う email を保存しておく
      const isSignUp = !res.user.userId
      dispatch(loginSuccess(isSignUp ? { ...res, user: { ...cleanState.user, email } } : res))
    } catch (res) {
      const errorCode = makeErrorMessage(res as AxiosError)
      if (errorCode === ERROR_STATUS_CODE.UNREACHABLE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const logout = (): AppThunk => async (dispatch, getState) => {
  const { accessToken, idToken } = getState().session
  dispatch(Spinner.start())
  try {
    await API.logout({ accessToken, idToken })
  } finally {
    dispatch(sessionClear())
    dispatch(Spinner.stop())
  }
}

export const activate =
  ({ password, name }: { password: string; name: string }): AppThunk =>
  async (dispatch, getState) => {
    const {
      session,
      user: { email },
    } = getState().session
    if (!session) {
      dispatch(apiFailure({ errorMessage: 'null session' }))
      return
    }

    dispatch(startRequest())
    dispatch(Spinner.start())
    try {
      const res = await API.activate({ email, password, session, name })
      dispatch(activateSuccess(res))
    } catch (res) {
      dispatch(apiFailure({ errorMessage: makeErrorMessage(res as AxiosError) }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const requestPasswordReset =
  (data: RequestPasswordResetParams): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    dispatch(Spinner.start())
    try {
      await API.requestPasswordReset(data)
      dispatch(requestPasswordResetSuccess())
    } catch (res) {
      const errorCode = makeErrorMessage(res as AxiosError)
      if (errorCode === ERROR_STATUS_CODE.UNREACHABLE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const confirmPasswordReset =
  (data: ConfirmPasswordResetParams): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    dispatch(Spinner.start())
    try {
      await API.confirmPasswordReset(data)
      dispatch(confirmPasswordResetSuccess())
    } catch (res) {
      const errorCode = makeErrorMessage(res as AxiosError)
      if (errorCode === ERROR_STATUS_CODE.UNREACHABLE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const updateAccountPassword =
  (data: UpdateUserPasswordParams): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      const res = await API.updateUserPassword(commonParams(getState), data)
      dispatch(updateAccountInformationSuccess(res))
    } catch (res) {
      const err = res as AxiosError<{ message: string }>
      if (err.response?.data?.message === INCORRECT_OLD_PASSWORD_MESSAGE) {
        dispatch(apiFailure({ errorMessage: INCORRECT_OLD_PASSWORD_MESSAGE }))
        return
      }
      const errorCode = makeErrorMessage(err)
      if (errorCode === ERROR_STATUS_CODE.UNAUTHORIZED) {
        dispatch(SessionTimeoutDialog.open())
      } else if (errorCode === ERROR_STATUS_CODE.UNREACHABLE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

// セッションの期限の管理
export const validateToken = (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
  const { session } = getState()
  if (moment(session.expirationDate).isAfter(moment())) {
    return true
  }
  if (session.user.userHasTenants[0].role === ROLE.TENANT_ADMIN) {
    dispatch(SessionTimeoutDialog.open())
    return false
  }

  try {
    // トークンのリフレッシュでは Spinner もエラーダイアログも出さない
    const res = await API.refresh({ refreshToken: session.refreshToken ?? '' })
    dispatch(refreshSuccess(res))
    return true
  } catch {
    return false
  }
}

export const asyncValidateToken = createAsyncThunk<SessionState | undefined, void, { state: RootState }>(
  'session/validateToken',
  async (_args, { dispatch, getState }) => {
    const { session } = getState()
    if (moment(session.expirationDate).isAfter(moment())) {
      return session
    }
    if (session.user.userHasTenants[0].role === ROLE.TENANT_ADMIN) {
      dispatch(SessionTimeoutDialog.open())
      return
    }

    try {
      // トークンのリフレッシュでは Spinner もエラーダイアログも出さない
      const res = await API.refresh({ refreshToken: session.refreshToken ?? '' })
      dispatch(refreshSuccess(res))
      const { session: refreshedSession } = getState()
      return refreshedSession
    } catch {
      return
    }
  }
)

export const selectSessionStatus = (state: RootState) => ({ ...state.session })

export default sessionSlice.reducer
