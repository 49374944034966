import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/bop_reports/bop_reports'
import type {
  BopMonitoringReportParam,
  BopReportsBopResponse,
  BopReportsLaborCostsResponse,
  BopReportsProfitAndLossResponse,
  BopReportsUnitCostsResponse,
  BopReportUnitCostsParam,
  CreateBopExportDataInfo,
} from 'api/bop_reports/types'

import { downloadByURL, handleApiError } from 'slices/utils'

import { showError } from './notificationSlice'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type BopReportsState = {
  isRequesting: boolean
  errorMessage: string
  bopReports: BopReportsProfitAndLossResponse | undefined
  bopReportsBop: BopReportsBopResponse | undefined
  bopReportsLaborCosts: BopReportsLaborCostsResponse | undefined
  bopReportsUnitCosts: BopReportsUnitCostsResponse | undefined
}

const initialState: BopReportsState = {
  isRequesting: false,
  errorMessage: '',
  bopReports: undefined,
  bopReportsBop: undefined,
  bopReportsLaborCosts: undefined,
  bopReportsUnitCosts: undefined,
}

export const bopReportsSlice = createSlice({
  name: 'bopReports',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getBopReportsSuccess: (state, action: PayloadAction<BopReportsBopResponse>) => {
      state.isRequesting = false
      state.bopReportsBop = action.payload
    },
    getBopReportsProfitAndLossSuccess: (state, action: PayloadAction<BopReportsProfitAndLossResponse>) => {
      state.isRequesting = false
      state.bopReports = action.payload
    },
    getBopReportsLaborCostsSuccess: (state, action: PayloadAction<BopReportsLaborCostsResponse>) => {
      state.isRequesting = false
      state.bopReportsLaborCosts = action.payload
    },
    getBopReportsUnitCostsSuccess: (state, action: PayloadAction<BopReportsUnitCostsResponse>) => {
      state.isRequesting = false
      state.bopReportsUnitCosts = action.payload
    },
    getExportDataUrlSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getBopReportsSuccess,
  getBopReportsProfitAndLossSuccess,
  getBopReportsLaborCostsSuccess,
  getBopReportsUnitCostsSuccess,
  getExportDataUrlSuccess,
} = bopReportsSlice.actions

export const getBopReports =
  (data: BopMonitoringReportParam): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getBopReportsBop(data)
      dispatch(getBopReportsSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getBopReportsProfitAndLoss =
  (from: string, to: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getBopReportsProfitAndLoss(from, to)
      dispatch(getBopReportsProfitAndLossSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getBopReportsLaborCosts =
  (data: BopMonitoringReportParam): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getBopReportsLaborCosts(data)
      dispatch(getBopReportsLaborCostsSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getBopReportsUnitCosts =
  (data: BopReportUnitCostsParam): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getBopReportsUnitCosts(data)
      dispatch(getBopReportsUnitCostsSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const exportBopReport =
  (data: CreateBopExportDataInfo, fileName: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const { requestId } = await API.createBopExportData(data)
      if (!requestId) {
        return
      }

      const fetchBopExportData = async () => {
        const exportDataResponse = await API.getBopExportData(requestId)
        if (exportDataResponse?.downloadUrl) {
          dispatch(getExportDataUrlSuccess())
          return await downloadByURL(exportDataResponse.downloadUrl, fileName)
        }
        await fetchBopExportData()
      }
      try {
        await fetchBopExportData()
      } catch {
        dispatch(showError())
      }
    } catch (err) {
      handleApiError(err as AxiosError, dispatch, apiFailure)
    }
  }

export const selectBopReportsStatus = (state: RootState) => ({ ...state.bopReports })

export default bopReportsSlice.reducer
