import dayjs from 'dayjs'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { exportBopReport } from 'slices/bopReportsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal, SingleDateRangePicker, ItemEdit } from 'components/common'
import type { SuggestionItem } from 'components/common/types'

import useBusinessTime from 'hooks/useBusinessTime'

import type { BopPathItemType } from './BopReportsCommon'

type Props = {
  open: boolean
  setOpen: (prop: boolean) => void
  selectedWorkspaceIds: number[]
  bopPathItem: BopPathItemType
}

const DATE_PICKER_MAX_DAYS = 31

const CsvExportDialog = ({ open, setOpen, selectedWorkspaceIds, bopPathItem }: Props) => {
  const [openRangeDatePicker, setOpenRangeDatePicker] = useState(false)
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<SuggestionItem[]>([])
  const [period, setPeriod] = useState<{ start: Date; end: Date }>()
  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { getWorkDate } = useBusinessTime()

  const initExportSettings = useCallback(() => {
    if (selectedWorkspaceIds.length === 0) {
      return
    }

    const targetWorkspaces = partialWorkspaces
      .filter(workspace => selectedWorkspaceIds.includes(workspace.id))
      .map(target => ({ id: target.id, value: target.name }))
    setSelectedWorkspaces(targetWorkspaces)
    setPeriod(undefined)
  }, [partialWorkspaces, selectedWorkspaceIds])

  // ダイアログを開くたびに、設定を初期化する
  useEffect(() => {
    initExportSettings()
  }, [initExportSettings, open])

  const onApprove = () => {
    if (!period || selectedWorkspaces.length === 0) {
      return
    }
    const targetWorkspaces: number[] = selectedWorkspaces.map(w => (typeof w.id === 'number' ? w.id : Number(w.id)))
    const filename = `収支レポート（${bopPathItem.LABEL}）_${dayjs().format('YYYY-MM-DD')}.csv`
    const data = {
      startDate: dayjs(period.start).format('YYYY-MM-DD'),
      endDate: dayjs(period.end).format('YYYY-MM-DD'),
      targetWorkspaces,
      exportDataType: bopPathItem.EXPORT_DATA_TYPE,
    }
    dispatch(exportBopReport(data, filename))
    setOpen(false)
  }

  const disabled = useMemo(() => !period || selectedWorkspaces.length === 0, [period, selectedWorkspaces])

  const items = useMemo(() => partialWorkspaces.map(w => ({ id: w.id, value: w.name })), [partialWorkspaces])

  return (
    <div>
      <CustomModal
        isOpen={open}
        title="CSVエクスポート"
        onCancel={() => setOpen(false)}
        onHideNotification={() => {}}
        approveLabel="CSVエクスポート"
        onApprove={onApprove}
        approveDisabled={disabled}
        submitName="csv-export-dialog-submit"
      >
        <div className="ms-2">
          <div className="mb-4">
            <div className="my-2 fw-bold">ワークスペース選択</div>
            <div className="mb-2">他ワークスペースもまとめてエクスポートできます｡ワークスペースを選択してください｡</div>
            <ItemEdit
              items={items}
              selectedItems={selectedWorkspaces}
              label="ワークスペースを追加"
              itemName="ワークスペース"
              onChange={setSelectedWorkspaces}
            />
          </div>
          <div className="mb-4">
            <div className="my-2 fw-bold">エクスポート期間設定</div>
            <div className="mb-2">最大31日分エクスポートが可能です｡</div>
            <div className="d-flex">
              <Button outline onClick={() => setOpenRangeDatePicker(true)}>
                {period
                  ? `${dayjs(period.start).format('YYYY/MM/DD')} - ${dayjs(period.end).format('YYYY/MM/DD')}`
                  : '開始日と終了日を決定'}
              </Button>
              <SingleDateRangePicker
                isOpen={openRangeDatePicker}
                from={period && period.start}
                to={period && period.end}
                maxRange={DATE_PICKER_MAX_DAYS}
                maxDate={dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
                  .subtract(1, 'days')
                  .toDate()}
                onCancel={() => setOpenRangeDatePicker(false)}
                onChange={(start, end) => {
                  setPeriod({ start, end })
                  setOpenRangeDatePicker(false)
                }}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default CsvExportDialog
