import dayjs from 'dayjs'
import { isEqual } from 'es-toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Button, Card, CardBody, DropdownItem } from 'reactstrap'

import { SPOT_WORKER_SHIFT_STATUS } from 'api/spot_workers/constants'

import { showSuccess } from 'slices/notificationSlice'
import {
  deleteSpotWorkers,
  getAvailableSpotWorkers,
  getSpotWorkers,
  putSpotWorkerNew,
  putSpotWorkersStatusSickout,
  putSpotWorkersStatusUpdate,
  selectSpotWorkerStatus,
  saveSpotWorkers,
  assignSpotWorkerExistingId,
} from 'slices/spotWorkerSlice'

import {
  CardSubmitFooter,
  CustomButton,
  DateChangeButton,
  DropdownList,
  FilteringButton,
  FilteringInputField,
  NavMenu,
} from 'components/common'
import SpotWorkerTable from 'components/common/SpotWorkerTable/SpotWorkerTable'
import { checkIsColumnNameVerified } from 'components/common/SpotWorkerTable/utils'

import useDateChange from 'hooks/useDateChange'
import useDateQuery from 'hooks/useDateQuery'
import { localStorageKeys, useLocalStorage } from 'hooks/useLocalStorage'
import type { editDataKeyTypes } from 'hooks/useSpotWorker'
import useSpotWorker from 'hooks/useSpotWorker'
import useSpotWorkerCsv from 'hooks/useSpotWorkerCsv'

import placeholder from 'images/allEmpty.svg'

import { SpotWorkerCreateDialog } from './SpotWorkerCreateDialog'
import { SpotWorkerImportDialog } from './SpotWorkerImportDialog '
import { SpotWorkerRequestFailDialog } from './SpotWorkerRequestFailDialog'

import styles from './SpotWorker.module.scss'

type helpProps = {
  onClick: () => void
}

const Help = ({ onClick }: helpProps) => (
  <div className={`${styles.help} d-flex align-items-center justify-content-between`}>
    <div className="d-flex align-items-center">
      <i className="icf-info text-secondary font-middle mx-3" />
      <div>スポットメンバー管理はシフトにスポットメンバーを登録するための下書き機能です。</div>
    </div>
    <Button color="link" className="shadow-none text-dark" onClick={onClick}>
      この表示を閉じる
    </Button>
  </div>
)

const isSelectedColumnsMatchedTargetColumns = (selectedLineNumbers: number[], targetLineNumbers: number[]) =>
  selectedLineNumbers.every(lineNumber => targetLineNumbers.includes(lineNumber))

const SpotWorker = () => {
  const dispatch = useDispatch()
  const workDate = useDateQuery()
  const { value: isHelpOpen, setLocalStorageValue: setHelpOpen } = useLocalStorage<boolean>({
    key: localStorageKeys.isHelpOpen,
    initValue: true,
  })
  const { exportSpotWorkers } = useSpotWorkerCsv()
  const { checkTenantCreated } = useDateChange()
  const {
    checkSpotWorkerShiftStatusType,
    checkIsDateInEditableRange,
    initTableData,
    filteredTableData,
    tableData,
    setTableData,
    targetWorkspaces,
    selectedStatus,
    selectedWorkspaces,
    selectedSkills,
    setSelectedStatus,
    setSelectedWorkspaces,
    setSelectedSkills,
    setFilterWorkerName,
    filterStatus,
    filterWorkspaces,
    filterSkills,
    filterWorkerName,
  } = useSpotWorker()

  const { spotWorkerListResponse, isRequesting, errorMessage, failedColumnNames } = useSelector(
    selectSpotWorkerStatus,
    shallowEqual
  )
  const [isImportDialogOpen, setIsImportDialogOpen] = useState<boolean>(false)
  const [isAdditionDialogOpen, setIsAdditionDialogOpen] = useState<boolean>(false)
  const [isSelectWorkspaceOpen, setIsSelectWorkspaceOpen] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<number[]>([])
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isValidAllColumns, setIsValidAllColumns] = useState<boolean>(true)

  useEffect(() => {
    dispatch(getSpotWorkers(workDate))
  }, [workDate, dispatch])

  useEffect(() => {
    if (submitted) {
      setSelectedUser([])
    }
  }, [submitted])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '' && failedColumnNames.length === 0) {
      dispatch(showSuccess())
    }
    // エラーはダイアログで表示する
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workDate, failedColumnNames])

  const checkDataWithinValidRange = useCallback((newDate: Date) => {
    const targetDate = newDate.getTime()
    const date425DaysAgo = new Date().getTime() - 425 * 24 * 60 * 60 * 1000
    const data45DaysFuture = new Date().getTime() + 45 * 24 * 60 * 60 * 1000
    return targetDate > date425DaysAgo && targetDate < data45DaysFuture
  }, [])

  const handleDetailClick = () => window.open('https://help.smileboard.jp/day-laborer-skill-management', '_blank')

  const handleWorkspaceSelect = useCallback(
    (workspace: { id: number; name: string }) => {
      setTableData(
        filteredTableData
          .filter(data => data.lineNumber)
          .map(data => {
            if (
              data.status === SPOT_WORKER_SHIFT_STATUS.SICK_OUT ||
              !selectedUser.includes(data.lineNumber) ||
              data.workspace?.id === workspace.id
            ) {
              return data
            }
            return {
              ...data,
              workspace,
              group: null,
            }
          })
      )
    },
    [filteredTableData, selectedUser, setTableData]
  )

  // 保存ボタン押下時のデータ
  const targetSaveColumns = useMemo(() => {
    return filteredTableData
      .filter(
        data =>
          !isEqual(
            initTableData.find(d => d.lineNumber === data.lineNumber),
            data
          ) && data.status !== SPOT_WORKER_SHIFT_STATUS.SICK_OUT
      )
      .map(data => ({
        revision: data.revision,
        lineNumber: data.lineNumber,
        workerId: data.workerId,
        workspaceId: data.workspace?.id || null,
        groupId: data.group?.id || null,
        wmsMemberId: data.wmsMemberId,
        workTemplateId: data.template?.id || null,
        workStart1: data.workStart1,
        workEnd1: data.workEnd1,
        workStart2: data.workStart2,
        workEnd2: data.workEnd2,
        workStart3: data.workStart3,
        workEnd3: data.workEnd3,
      }))
  }, [filteredTableData, initTableData])

  const unChanged = useMemo(() => targetSaveColumns.length === 0, [targetSaveColumns])

  // ユーザーによって個別、一括操作されるデータ
  const selectedColumns = useMemo(() => {
    return filteredTableData.filter(data => selectedUser.includes(data.lineNumber))
  }, [filteredTableData, selectedUser])

  const selectedLineNumbers = useMemo(() => selectedColumns.map(data => data.lineNumber), [selectedColumns])

  // 個別、一括操作押下時の保存データ
  const selectedSaveColumns = useMemo(
    () => targetSaveColumns.filter(data => selectedUser.includes(data.lineNumber)),
    [targetSaveColumns, selectedUser]
  )

  // 登録・更新押下時のデータ
  const targetUpdateColumns = useMemo(() => {
    return selectedColumns
      .filter(
        data =>
          data.status === SPOT_WORKER_SHIFT_STATUS.NON_UPDATED ||
          data.status === SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED
      )
      .map(data => ({
        lineNumber: data.lineNumber,
        revision: data.revision,
      }))
  }, [selectedColumns])

  // 欠勤押下時のデータ
  const targetSickoutColumns = useMemo(() => {
    return selectedColumns
      .filter(
        data =>
          data.status === SPOT_WORKER_SHIFT_STATUS.NON_UPDATED ||
          data.status === SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED
      )
      .map(data => ({
        lineNumber: data.lineNumber,
        revision: data.revision,
      }))
  }, [selectedColumns])

  // メンバー追加押下時のデータ
  const targetNewMemberIdColumns = useMemo(() => {
    return selectedColumns
      .filter(data => data.status === SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT)
      .map(data => ({
        lineNumber: data.lineNumber,
        revision: data.revision,
        workerId: data.workerId,
      }))
  }, [selectedColumns])

  // 削除押下時のデータ
  const targetDeleteColumns = useMemo(() => {
    return selectedColumns.map(data => ({
      lineNumber: data.lineNumber,
      revision: data.revision,
    }))
  }, [selectedColumns])

  // 名前照合確認押下時のデータ
  const targetNameVerificationColumns = useMemo(() => {
    return selectedColumns
      .filter(data => checkIsColumnNameVerified(data))
      .map(data => ({
        lineNumber: data.lineNumber,
        revision: data.revision,
        workerId: Number(data.suggestedWorkerId),
      }))
  }, [selectedColumns])

  const isDeleteColumnsDisabled = useMemo(
    () => selectedUser.length === 0 || !checkIsDateInEditableRange(workDate),
    [selectedUser, workDate, checkIsDateInEditableRange]
  )

  const isUpdateColumnsDisabled = useMemo(
    () =>
      !isSelectedColumnsMatchedTargetColumns(
        selectedLineNumbers,
        targetUpdateColumns.map(data => data.lineNumber)
      ) || isDeleteColumnsDisabled,
    [selectedLineNumbers, isDeleteColumnsDisabled, targetUpdateColumns]
  )

  const isSickoutColumnsDisabled = useMemo(
    () =>
      !isSelectedColumnsMatchedTargetColumns(
        selectedLineNumbers,
        targetSickoutColumns.map(data => data.lineNumber)
      ) || isDeleteColumnsDisabled,
    [selectedLineNumbers, isDeleteColumnsDisabled, targetSickoutColumns]
  )

  const isNewColumnsDisabled = useMemo(
    () =>
      !isSelectedColumnsMatchedTargetColumns(
        selectedLineNumbers,
        targetNewMemberIdColumns.map(data => data.lineNumber)
      ) || isDeleteColumnsDisabled,
    [selectedLineNumbers, targetNewMemberIdColumns, isDeleteColumnsDisabled]
  )

  const isSelectWorkspaceDisabled = useMemo(
    () => selectedColumns.some(data => data.status === SPOT_WORKER_SHIFT_STATUS.SICK_OUT) || isDeleteColumnsDisabled,
    [selectedColumns, isDeleteColumnsDisabled]
  )

  const isNameVerificationDisabled = useMemo(
    () =>
      !isSelectedColumnsMatchedTargetColumns(
        selectedLineNumbers,
        targetNameVerificationColumns.map(data => data.lineNumber)
      ) || isDeleteColumnsDisabled,
    [selectedLineNumbers, targetNameVerificationColumns, isDeleteColumnsDisabled]
  )

  const handleSaveClick = useCallback(() => {
    dispatch(saveSpotWorkers(workDate, targetSaveColumns))
    setSubmitted(true)
  }, [dispatch, workDate, targetSaveColumns])

  const handleUpdateClick = useCallback(() => {
    dispatch(putSpotWorkersStatusUpdate(workDate, targetUpdateColumns, selectedSaveColumns))
    setSubmitted(true)
  }, [dispatch, workDate, targetUpdateColumns, selectedSaveColumns])

  const handleSickoutClick = useCallback(() => {
    dispatch(putSpotWorkersStatusSickout(workDate, targetSickoutColumns, selectedSaveColumns))
    setSubmitted(true)
  }, [dispatch, workDate, targetSickoutColumns, selectedSaveColumns])

  const handleNewWorkerIdClick = useCallback(() => {
    dispatch(putSpotWorkerNew(workDate, targetNewMemberIdColumns, selectedSaveColumns))
    setSubmitted(true)
  }, [dispatch, workDate, targetNewMemberIdColumns, selectedSaveColumns])

  const handleNameVerificationClick = useCallback(() => {
    dispatch(assignSpotWorkerExistingId(workDate, targetNameVerificationColumns, selectedSaveColumns))
    setSubmitted(true)
  }, [dispatch, workDate, targetNameVerificationColumns, selectedSaveColumns])

  const handleDeleteColumnClick = useCallback(() => {
    dispatch(deleteSpotWorkers(workDate, targetDeleteColumns, selectedSaveColumns))
    setSubmitted(true)
  }, [dispatch, workDate, targetDeleteColumns, selectedSaveColumns])

  const handleSpotWorkerCreateDialogOpen = useCallback(() => {
    setIsAdditionDialogOpen(true)
    dispatch(getAvailableSpotWorkers(workDate))
  }, [dispatch, workDate])

  const handleDateChange = useCallback(
    (newDate: string) => {
      checkTenantCreated(newDate)
    },
    [checkTenantCreated]
  )

  const handleColumnChange = useCallback(
    (lineNumber: number, key: editDataKeyTypes, value: { id: number; name: string } | string | null) => {
      setTableData(prev =>
        prev.map(column => {
          if (column.lineNumber === lineNumber) {
            return {
              ...column,
              [key]: value,
            }
          }
          return column
        })
      )
    },
    [setTableData]
  )

  const spotWorkerCountStatement = useMemo(() => {
    const totalCount = filteredTableData.length
    const sickoutCount = filteredTableData.filter(data => data.status === SPOT_WORKER_SHIFT_STATUS.SICK_OUT).length
    return `${totalCount}人（うち欠勤：${sickoutCount}人）`
  }, [filteredTableData])

  return (
    <NavMenu>
      <div className="d-flex flex-column h-100 mx-3">
        <div className="d-flex flex-column my-3 row-gap-3">
          <div className="font-x-large fw-bold">スポットメンバー管理</div>
          {isHelpOpen && <Help onClick={() => setHelpOpen(false)} />}
        </div>
        <Card className="position-relative flex-grow-1 bg-white mb-3">
          <CardBody className="d-flex flex-column row-gap-3 py-3 pt-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex column-gap-2 align-items-center">
                <DateChangeButton
                  date={workDate}
                  popupPosition="bottom right"
                  filterDate={newDate => checkDataWithinValidRange(newDate)}
                  onChange={newDate => handleDateChange(dayjs(newDate).format('YYYY-MM-DD'))}
                  isWorkPlanView
                />
                <div className="font-x-large fw-bold">のスポットメンバー</div>
                <Button
                  color="link"
                  className="shadow-none"
                  disabled={!checkDataWithinValidRange(dayjs(workDate).subtract(1, 'day').toDate())}
                  onClick={() => handleDateChange(dayjs(workDate).subtract(1, 'day').format('YYYY-MM-DD'))}
                >
                  前の日
                </Button>
                <Button
                  color="link"
                  className="shadow-none"
                  disabled={!checkDataWithinValidRange(dayjs(workDate).add(1, 'day').toDate())}
                  onClick={() => handleDateChange(dayjs(workDate).add(1, 'day').format('YYYY-MM-DD'))}
                >
                  次の日
                </Button>
              </div>
              <div className="d-flex column-gap-2 align-items-center">
                <CustomButton
                  outline
                  onClick={() => setIsImportDialogOpen(true)}
                  disabled={!checkIsDateInEditableRange(workDate)}
                >
                  インポート
                </CustomButton>
                <CustomButton
                  outline
                  onClick={() => {
                    exportSpotWorkers(tableData)
                  }}
                >
                  エクスポート
                </CustomButton>
                <CustomButton
                  icon="plus"
                  outline
                  onClick={handleSpotWorkerCreateDialogOpen}
                  disabled={!checkIsDateInEditableRange(workDate)}
                >
                  既存のスポットメンバーから追加
                </CustomButton>
              </div>
            </div>
            <hr className="my-0" />
            {tableData.length > 0 ? (
              <div className="d-flex flex-grow-1 row-gap-3 flex-column">
                <div className="d-flex column-gap-2 align-items-center">
                  <FilteringInputField
                    onChange={setFilterWorkerName}
                    placeholder="メンバー名で検索"
                    value={filterWorkerName}
                  />
                  <FilteringButton
                    items={filterWorkspaces}
                    onChange={setSelectedWorkspaces}
                    value={selectedWorkspaces}
                    label="配属先ワークスペースで絞り込み"
                    isEnableUnCheckAll={true}
                  />
                  <FilteringButton
                    items={filterSkills}
                    onChange={setSelectedSkills}
                    value={selectedSkills}
                    label="スキルで絞り込み"
                    isEnableUnCheckAll={true}
                  />
                  <FilteringButton
                    items={filterStatus}
                    onChange={values => setSelectedStatus(values.filter(checkSpotWorkerShiftStatusType))}
                    value={selectedStatus}
                    label="その他"
                    isEnableUnCheckAll={true}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex column-gap-2">
                    <CustomButton outline onClick={handleUpdateClick} disabled={isUpdateColumnsDisabled}>
                      登録・更新
                    </CustomButton>
                    <CustomButton outline onClick={handleSickoutClick} disabled={isSickoutColumnsDisabled}>
                      欠勤
                    </CustomButton>
                    <CustomButton outline onClick={handleNewWorkerIdClick} disabled={isNewColumnsDisabled}>
                      メンバー登録
                    </CustomButton>
                    <DropdownList
                      open={isSelectWorkspaceOpen}
                      setOpen={() => {
                        setIsSelectWorkspaceOpen(prev => !prev && !isSelectWorkspaceDisabled)
                      }}
                      content={
                        <CustomButton outline disabled={isSelectWorkspaceDisabled}>
                          配属先ワークスペース選択
                        </CustomButton>
                      }
                    >
                      {targetWorkspaces.map(workspace => {
                        return (
                          <DropdownItem key={workspace.id} onClick={() => handleWorkspaceSelect(workspace)}>
                            {workspace.name}
                          </DropdownItem>
                        )
                      })}
                    </DropdownList>
                    <CustomButton outline onClick={handleNameVerificationClick} disabled={isNameVerificationDisabled}>
                      名前照合確認
                    </CustomButton>
                    <Button color="danger" outline onClick={handleDeleteColumnClick} disabled={isDeleteColumnsDisabled}>
                      削除
                    </Button>
                  </div>
                  <div className="text-muted d-flex align-items-center font-x-small">{spotWorkerCountStatement}</div>
                </div>
                <div className={`w-100 ${styles.spotWorkerTable}`}>
                  <SpotWorkerTable
                    handleColumnChange={handleColumnChange}
                    initData={initTableData}
                    editData={filteredTableData}
                    selectedUser={selectedUser}
                    onSelectUser={users => setSelectedUser(users)}
                    onChangeTableData={isValid => setIsValidAllColumns(isValid)}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column row-gap-4 justify-content-center align-items-center flex-grow-1">
                <img className={styles.placeholderImage} src={placeholder} alt="" />
                <div className="font-middle fw-bold">スポットメンバーがまだ登録されていません</div>
                <div>まずは最初のスポットメンバーを登録してみましょう。</div>
                <Button size="sm" outline onClick={handleDetailClick}>
                  スポットメンバー管理についてもっと詳しく
                </Button>
              </div>
            )}
          </CardBody>
          {tableData.length > 0 && (
            <div>
              <CardSubmitFooter
                onCancel={() => setTableData(initTableData)}
                onSubmit={handleSaveClick}
                updatedBy={spotWorkerListResponse?.updatedAtByName || ''}
                updatedAt={spotWorkerListResponse?.updatedAt || ''}
                submitDisabled={unChanged || !isValidAllColumns || !checkIsDateInEditableRange(workDate)}
                cancelDisabled={unChanged}
              />
            </div>
          )}
        </Card>
        <SpotWorkerImportDialog
          key={`spot-worker-import-dialog-${isImportDialogOpen.toString()}`}
          isOpen={isImportDialogOpen}
          workDate={workDate}
          onCancel={() => setIsImportDialogOpen(false)}
          onSuccess={() => setIsImportDialogOpen(false)}
        />
        <SpotWorkerCreateDialog
          key={`spot-worker-create-dialog-${isAdditionDialogOpen.toString()}`}
          isOpen={isAdditionDialogOpen}
          workDate={workDate}
          onSuccess={() => setIsAdditionDialogOpen(false)}
          onCancel={() => setIsAdditionDialogOpen(false)}
        />
        <SpotWorkerRequestFailDialog />
      </div>
    </NavMenu>
  )
}

export default SpotWorker
