import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import type { TenantData } from 'api/tenants/types'

import { selectTenantsStatus } from 'slices/tenantsSlice'

import { PERIOD_LIST } from 'components/Reports/utils'
import { TIME_INTERVAL } from 'components/common/constants'
import type { TimeIntervalType } from 'components/common/types'

import type { Dayjs } from 'dayjs'

type BusinessTimeType = {
  interval?: TimeIntervalType
  tenantWithDate?: TenantData
}

// tenantsから営業時間を取得するため、このhooksを使用する前に一度getTenantsを実行する必要がある
// 複数回リクエストされないようにhooks内でリクエストは行わない
const useBusinessTime = (props?: BusinessTimeType) => {
  const { interval = TIME_INTERVAL.FIFTEEN, tenantWithDate } = props || {}
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  // 日付が無い文字列をパースする
  const parseDate = useCallback((date: string, format: string) => {
    const dateFormat = 'YYYYMMDD'
    return dayjs(`${dayjs().format(dateFormat)}${date}`, `${dateFormat}${format}`)
  }, [])

  const businessTime = useMemo(() => {
    const targetTenant = tenantWithDate || tenant
    if (!targetTenant) {
      return {
        businessStartTime: '06:00',
        businessEndTime: '20:00',
        businessDuration: 14 * (60 / interval),
        businessStartIndex: 0,
      }
    }
    const { businessStartTime, businessEndTime } = targetTenant
    const startDate = parseDate(businessStartTime, 'HH:mm')
    const businessStartIndex = startDate.diff(startDate.startOf('d'), 'm') / interval

    return {
      businessStartTime,
      businessEndTime,
      businessDuration: parseDate(businessEndTime, 'HH:mm').diff(parseDate(businessStartTime, 'HH:mm'), 'm') / interval,
      businessStartIndex,
    }
  }, [parseDate, tenant, tenantWithDate, interval])

  const { businessHourBlocks, business15MinBlocks } = useMemo(() => {
    const beginningOfDay = dayjs().startOf('day')
    const startTime = businessTime.businessStartTime.split(':')
    const endTime = businessTime.businessEndTime.split(':')
    const start = dayjs().hour(Number(startTime[0])).minute(Number(startTime[1]))
    const end = dayjs().hour(Number(endTime[0])).minute(Number(endTime[1]))

    const startUnixTime = start.unix()
    const endUnixTime = end.unix()
    const hourStart = Math.floor((startUnixTime - beginningOfDay.unix()) / 3600)
    const hourEnd = Math.ceil((endUnixTime - beginningOfDay.unix()) / 3600)
    const minOffset = Math.floor((start.unix() - beginningOfDay.unix()) / 900)
    const minBlocks = Math.ceil((end.unix() - start.unix()) / 900)
    const hourBlockNum = endTime[1] === '00' ? hourEnd - hourStart - 1 : hourEnd - hourStart

    return {
      businessHourBlocks: [...Array(hourBlockNum)].map((_, index) => hourStart + index),
      business15MinBlocks: [...Array(minBlocks)].map((_, index) => minOffset + index),
    }
  }, [businessTime])

  // 引数の時間が営業開始時間前だった場合は引数の時の値に24足した値を返す
  const getHourOver24h = useCallback(
    (time: string) => {
      const [hour, minute] = time.split(':').map(Number)
      const [businessStartHour, businessStartMinute] = businessTime.businessStartTime.split(':').map(Number)

      return hour < businessStartHour || (hour === businessStartHour && minute <= businessStartMinute)
        ? `${hour + 24}`
        : hour.toString().padStart(2, '0')
    },
    [businessTime.businessStartTime]
  )

  /*
  開始時間を選択する際に利用する時間
  ・timeRange.start.fromHour、timeRange.start.fromMin -> 営業開始時間
  ・timeRange.start.toHour、timeRange.start.toMin -> 営業終了時間の15(5)分前
  終了時間を選択する際に利用する時間
  ・timeRange.end.fromHour、timeRange.end.fromMin -> 営業開始時間の15(5)分後
  ・timeRange.end.toHour、timeRange.end.toMin -> 営業終了時間
  */
  const timeRange = useMemo(() => {
    const [startHour, startMin] = businessTime.businessStartTime.split(':')
    const [endHour, endMin] = businessTime.businessEndTime.split(':')

    const startDate = dayjs().hour(Number(endHour)).minute(Number(endMin)).subtract(interval, 'minute')

    const endDate = dayjs().hour(Number(startHour)).minute(Number(startMin)).add(interval, 'minute')

    return {
      start: {
        fromHour: startHour,
        fromMin: startMin,
        toHour: getHourOver24h(startDate.format('HH')),
        toMin: startDate.format('mm'),
      },
      end: { fromHour: endDate.format('HH'), fromMin: endDate.format('mm'), toHour: endHour, toMin: endMin },
    }
  }, [businessTime.businessStartTime, businessTime.businessEndTime, interval, getHourOver24h])

  // 24:00を超える時間が当日と翌日どちらか判別のためにworkDateを基準日として使用する
  const getShiftBarXbyStartTime = useCallback(
    (startTime: string, workDate: string) => {
      const splitTime = businessTime.businessStartTime.split(':') as [string, string]
      const businessStart = dayjs(workDate).hour(Number(splitTime[0])).minute(Number(splitTime[1])).second(0)
      const start = dayjs(startTime).second(0)
      const delta = start.unix() - businessStart.unix() // businessStartTimeからの経過時間(秒)
      return delta / (60 * interval)
    },
    [businessTime, interval]
  )

  const getTimesByShiftBarX = useCallback(
    (x: number) => {
      const splitTime = businessTime.businessStartTime.split(':') as [string, string]
      const time = dayjs()
        .hour(Number(splitTime[0]))
        .minute(Number(splitTime[1]))
        .second(0)
        .add(interval * x, 'minutes')
      const isToday = time.startOf('day').isSame(dayjs().startOf('day'), 'days')

      return { hours: isToday ? time.hour() : time.hour() + 24, minutes: time.minute() }
    },
    [businessTime, interval]
  )

  // workDateが営業時間前の場合かのチェック
  const getWorkDate = useCallback(
    (workDate: string) => {
      const current = dayjs()

      // 現在日時とworkDateが一致しない場合はworkDateをそのまま返す
      if (workDate !== current.format('YYYY-MM-DD')) {
        return workDate
      }

      const startDateTime = dayjs(`${workDate} ${businessTime.businessStartTime}`, 'YYYY-MM-DD HH:mm')
      const isBefore = current.isBefore(startDateTime)

      // 現在日時が営業時間前の場合はworkDateの1日前の日時を返す
      return isBefore ? current.subtract(1, 'day').format('YYYY-MM-DD') : current.format('YYYY-MM-DD')
    },
    [businessTime]
  )

  // 表示している日付が営業日より前の日付かチェック
  const getIsPast = useCallback(
    (displayDate: string) => dayjs(displayDate).isBefore(getWorkDate(dayjs().format('YYYY-MM-DD'))),
    [getWorkDate]
  )

  // 営業時間前かチェックするgetWorkDateを使って、それから「過去7日間」を計算する
  const getPeriod = useCallback(() => {
    const today = getWorkDate(dayjs().format('YYYY-MM-DD'))
    return {
      start: dayjs(today).subtract(PERIOD_LIST.DAYS_7, 'days').toDate(),
      end: dayjs(today).subtract(1, 'day').toDate(),
    }
  }, [getWorkDate])

  const getTimeOver24h = useCallback(
    (time: string, isStart = false) => {
      const splittedTime = time.split(':')
      const splittedStartTime = businessTime.businessStartTime.split(':')

      return isStart && (time === businessTime.businessStartTime || splittedTime[0] === splittedStartTime[0])
        ? time
        : `${getHourOver24h(time)}:${splittedTime[1]}`
    },
    [businessTime.businessStartTime, getHourOver24h]
  )

  const getFlooredAndCeiledTimes = useCallback(
    (now: Dayjs) => {
      // 00:00 からの経過分数（切り上げ時に秒数を考慮するために秒数を分に変換）
      const startOfDay = now.startOf('day')
      const minutesOfDay = now.diff(startOfDay, 'second') / 60

      const flooredTime = startOfDay.add(Math.floor(minutesOfDay / interval) * interval, 'minute')
      const flooredCurrentTime = `${getHourOver24h(flooredTime.format('HH'))}:${flooredTime.format('mm')}`

      const ceiledTime = startOfDay.add(Math.ceil(minutesOfDay / interval) * interval, 'minute')
      const ceiledCurrentTime = `${getHourOver24h(ceiledTime.format('HH'))}:${ceiledTime.format('mm')}`

      return { flooredCurrentTime, ceiledCurrentTime }
    },
    [getHourOver24h, interval]
  )

  const currentTime = useMemo(() => {
    return getFlooredAndCeiledTimes(dayjs())
  }, [getFlooredAndCeiledTimes])

  return {
    ...businessTime,
    businessHourBlocks,
    business15MinBlocks,
    timeRange,
    getShiftBarXbyStartTime,
    getTimesByShiftBarX,
    getIsPast,
    getWorkDate,
    getHourOver24h,
    getTimeOver24h,
    getPeriod,
    ...currentTime,
    getFlooredAndCeiledTimes,
  }
}

export default useBusinessTime
